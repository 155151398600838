import React from 'react';

function Skills() {
    return (
        <div>
            <div className='mb-4'>
                <div className="rounded-md bg-white shadow border border-slate-100 p-6  dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-700">
                    <div className="title mb-4 text-sm font-semibold text-zinc-900 dark:text-gray-100">
                        <h2 className='flex dark:text-zinc-400'>
                            <svg className="svg-icon" viewBox="0 0 20 20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" className="h-5 w-5 fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500 flex-none me-2" >
                                <path d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"></path>
                            </svg> Compétences professionnelles</h2>
                    </div>
                    <div className="progress-dark progress-sm text-black">
                        <div className="text-slate-800">
                            <div className="progress-item mb-4">
                                <p className='font-bold text-dark  dark:text-gray-100'>Développement Web</p>
                                <div className="w-full bg-blue-100 rounded-full h-2 dark:bg-blue-100/50">
                                    <div className="bg-sky-600 h-2 rounded-full w-[90%] transition-all duration-500" ></div>
                                </div>
                                <small className='text-muted dark:text-gray-400'>Laravel, Symfony, React.js, Vue.js, Node.js, Python</small>
                            </div>
                            <div className="progress-item mb-4">
                                <p className='font-bold dark:text-gray-100'>Design graphique / Web design</p>
                                <div className="w-full bg-pink-200 rounded-full h-2 dark:bg-pink-200/50">
                                    <div className="bg-pink-700 h-2 rounded-full w-[75%] transition-all duration-500" ></div>
                                </div>
                                <small className='text-muted dark:text-gray-400'>Adobe Photoshop, Illustrator, Première pro, InDesign, Figma</small>
                            </div>
                            <div className="progress-item mb-4">
                                <p className='font-bold dark:text-gray-100'>Administration de base de données</p>
                                <div className="w-full bg-teal-200 rounded-full h-2 dark:bg-teal-200/50">
                                    <div className="bg-teal-900 dark:bg-teal-500 h-2 rounded-full w-[80%] transition-all duration-500" ></div>
                                </div>
                                <small className='text-muted dark:text-gray-400'>MySQL/MariaDb, PostgreSQL, MongoDb</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills