import React from "react";
import { Link } from "react-router-dom";

export default function Pagination({
    postsPerPage,
    lastPage,
    currentPage,
    url,
    onchange
}) {
    
  const paginatePrev = +currentPage - 1;
  const paginateNext = +currentPage + 1;
  
    const rows = [];
    for (let i = 1; i <= lastPage; i++) {
        rows.push(i);
    }
    return (

        <div className='py-2'>
            {lastPage > 1 &&
                <nav aria-label="Pagination">
                    <ul className="text-sm font-medium text-gray-500 inline-flex -space-x-px text-base h-10 text-sm text-gray-700">
                        {paginatePrev>0 ?
                        <li>
                        <Link to={url+"page/"  + paginatePrev} onClick={() => {
                                onchange(paginatePrev);
                            }} className="font-semin flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Précédent</Link>
                        </li> :
                        <li>
                        <a href="#" className="font-semin flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 opacity-50 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Précédent</a>
                        </li>
                        }
                        {rows && rows.map((elt,index)=>(
                            <li key={index}>
                                <Link to={url+"page/" + elt}  onClick={() => {
                                onchange(elt);
                            }} className={elt==currentPage?"flex items-center justify-center px-4 h-10 leading-tight text-white  bg-blue-500 dark:bg-slate-700/80 dark:text-zinc-400 border border-gray-300 hover:bg-blue-500 hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white":"flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"}>{elt}</Link>
                            </li>
                        ))}
                       {paginateNext<=lastPage ?
                        <li>
                            <Link to={url+"page/" + paginateNext} onClick={() => {
                                onchange(paginateNext);
                            }} className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Suivant</Link>
                        </li> :
                        <li>
                            <a href="#" className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 opacity-50 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Suivant</a>
                        </li>
                        }
                    </ul>
                </nav>
            }
           
        </div>
    );
}