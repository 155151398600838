import React from 'react'

import moment from 'moment';
import frLocale from "../i18/momen-js";
import { Link } from 'react-router-dom';
import { FaClock } from 'react-icons/fa';
import { HTTP} from '../datas/config';

export default function CardProjet({projet}) {
    moment.locale('fr', [frLocale]);
    let HTTP_WEB = HTTP.WEB ?HTTP.WEB:'';

    return (
        <article className="">
            {projet &&
                <div className="mx-auto relative h-full  w-full border border-slate-100  md:border-slate-50 max-w-[800px] flex-row rounded-xl bg-white bg-clip-border text-gray-700 md:shadow-sm dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-700/90 dark:text-zinc-300">
                    {projet.image && <div className="relative  m-0 w-full bg-none shrink-0 overflow-hidden  text-gray-700   pb-0 h-50 md:h-72">
                        <Link to={`/projet/` + projet.slug}>
                            <img
                                src={HTTP_WEB + projet.image}
                                alt={projet.titre}
                                className="h-full w-full object-cover  rounded-xl rounded-b-none"
                            />
                        </Link>
                    </div>
                    }
                    <div className="px-6 py-4">
                        <h6 className="hidden mb-4  font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-pink-500 antialiased">
                        {projet.type_projet}
                        </h6>
                        <p className="text-xs  text-slate-600 uppercase flex gap-1" title='Date début du projet'><FaClock/> {moment(projet.mois_debut+"/"+projet.annee_debut).format('MMMM YYYY')} {projet.duree && "/ "+projet.duree}</p>
                        <h4 className="font-serifs mb-2 block text-xl font-bold leading-snug tracking-normal text-blue-gray-900 antialiased">
                            <Link className='hover:text-teal-500' to={`/projet/` + projet.slug}> {projet.titre}</Link>
                        </h4>

                    </div>
                </div>
            }
        </article>
    )
}
