import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import LoadMiniExp from './loading/LoadMiniExp';
import { HTTP, PARCOURS } from '../datas/config';

function Works() {
    const [experiences, setExp] = useState([]);
    const [loading,setLoading]=useState(true);

    let HTTP_HOME = HTTP.WEB ?HTTP.WEB:''; 
    let HTTP_API = PARCOURS.ALL ?PARCOURS.ALL:''; 
    
    useEffect(() => {
        const getParcours = async (nb) => {
            let API_URL = `${HTTP_API}${nb}`;
            fetch(API_URL)
                .then(response => response.json())
                .then(json => {
                    setExp(json.data);
                    setLoading(false);
                })
                .catch(error => console.log(error));
        };

        getParcours(5);
    }, []);

   // console.log(experiences)
    return (
        <div className='py-4'>
            <div className="rounded-md  border border-slate-200  md:shadow-sm p-6  bg-white dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-700">
                <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                    <svg viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" className="h-6 w-6 flex-none">
                        <path d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z" className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"></path>
                        <path d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5" className="stroke-zinc-400 dark:stroke-zinc-500"></path>
                    </svg>
                    <span className="ml-3 dark:text-zinc-400">Expériences</span>
                </h2>
                <ol className="mt-6 space-y-4">
                    {loading && 
                    <>
                        <LoadMiniExp />
                        <LoadMiniExp />
                        <LoadMiniExp />
                        <LoadMiniExp />
                        <LoadMiniExp />
                    </>
                    }
                    {experiences && experiences.map((exp, index) => (
                        <li key={index} className="flex gap-4">
                            <div className="relative mt-1 flex h-10 w-10 flex-none items-center justify-center bg-white rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-600/50 dark:bg-zinc-800 dark:ring-0">
                                <a target='_blanck' href={exp.site_web != '' ? exp.site_web : '#'}>
                                    <img alt={exp.entreprise} src={HTTP_HOME + '' + exp.logo} loading="lazy" width="32" height="32" decoding="async" data-nimg="1" className="h-7 object-cover w-7" />
                                </a>
                            </div>
                            <dl className="flex flex-auto flex-wrap gap-x-2">
                                <dt className="sr-only">
                                    <a target='_blanck' href={exp.site_web != '' ? exp.site_web : '#'}>{exp.entreprise}</a>
                                </dt>
                                <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100"><Link target='_blanck' to={exp.site_web ? exp.site_web : '/experience/' + exp.id}>{exp.entreprise} </Link></dd>
                                <dt className="sr-only">{exp.poste} </dt>
                                <dd className="text-xs text-zinc-500 dark:text-zinc-400">{exp.poste} </dd>
                                <dt className="sr-only">{exp.date_debut} </dt>
                                <dd className="ml-auto text-xs text-zinc-400 dark:text-zinc-500" aria-label="2019 until Present"> </dd>
                            </dl>
                        </li>
                    ))}

                </ol>
                <Link to='/parcours' className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none bg-blue-500 text-white font-medium  hover:bg-blue-800 active:bg-zinc-100 active:text-zinc-900/60 dark:bg-zinc-800/50 dark:text-zinc-300 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 dark:active:bg-zinc-800/50 dark:active:text-zinc-50/70 group mt-6 w-full" >
                    En savoir plus
                    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="h-4 w-4 stroke-gray-100 -rotate-90 transition group-active:stroke-gray-100 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50">
                        <path d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </Link>
            </div>
            
        </div>
    )
}

export default Works
