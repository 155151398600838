import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    
    const year = new Date().getFullYear();
    return (
        <div>
            <div className="mx-auto max-w-6xl px-4 ">
                <div className="border-t border-zinc-200 pb-8 pt-5 dark:border-zinc-700/40">
                    <div className="relative">
                        <div className="mx-auto px-lg-8">
                            <div className="flex flex-col items-center justify-between gap-6 sm:flex-row">
                                <div className="flex flex-wrap justify-center gap-x-6 gap-y-1 text-sm font-medium text-zinc-800 dark:text-zinc-200">
                                
                                    <Link className="transition mb-4 md:mb-0  hover:text-teal-500 text-sm dark:hover:text-teal-400" to="/terms">Conditions d'utilisations</Link>
                                    <Link className="transition mb-4 md:mb-0  hover:text-teal-500 text-sm dark:hover:text-teal-400" to="/privacy">Politique de confidentialité</Link>
                                   </div> <div className="text-sm text-center font-normal text-md-start text-zinc-400 dark:text-zinc-400">
                                        © Wilfried ETEKA  {`${year}`}, All rights reserved.
                                    </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
