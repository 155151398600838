import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

function Translator() {
   
    return (
        <div id="google_translate_element"></div>
    ) 
}

export default Translator;