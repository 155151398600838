import React from 'react'

function LoadCard() {
    return (

        <div role="status" className="space-y-8 p-4 bg-slate-50 border-slate-100 mb-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center  rounded-md dark:dark:bg-gray-700 dark:border-gray-700 dark:hover:dark:bg-gray-600/90 dark:text-zinc-300">
           <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded-t md:rounded-l  dark:dark:bg-gray-500">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
           </div>
            <div className="w-full md:ps-0 px-4 pb-4 pt-sm-0 pb ps-md-0 py-md-4 -md-0 pe-md-4">
                <div className="h-2.5 bg-gray-200 rounded-full dark:dark:bg-gray-600 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:dark:bg-gray-600 w-10/12 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:dark:bg-gray-600 w-11/12 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full w-2/9 dark:dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:dark:bg-gray-600 max-w-[360px] mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:dark:bg-gray-600 w-10/12 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
            </div>
            <span className="sr-only">Chargement...</span>
        </div>

    )
}

export default LoadCard