import React from 'react'
import useTitle from '../components/useTitle'

export default function Privacy() {
    useTitle("Politique de confidentialité");
    return (
        <>
            <div className="max-w-6xl   my-12 mx-auto">
                <div className='mx-auto max-w-[800px] border border-zinc-50 p-4 md:p-10 bg-white md:rounded-md md:shadow-sm dark:bg-gray-700 dark:border-gray-700  dark:text-zinc-300'>
                    <h1 className='font-light text-2xl mb-4 dark:text-gray-300'>Politique de confidentialité</h1>
                    <div className="text-lg">
                    <p className='mb-6'>
                            <strong> Dernière mise à jour : </strong> 10 Août 2022
                        </p>
                        <p>
                            Bienvenue sur <a className='font-bold text-teal-500' href="https://wes.odacesoft.com">https://wes.odacesoft.com</a> (ci-après dénommé "le protefolio de Wilfried ETEKA").
                        </p>
                        <p>
                            La protection de vos données personnelles est une priorité pour nous.
                            Cette Politique de Confidentialité vise à vous informer sur la manière dont nous recueillons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre Site. En utilisant le Site, vous acceptez les pratiques décrites dans cette Politique de Confidentialité.
                        </p>
                        <h3 className='font-bold mt-6 mb-4'>1.  Collecte d'Informations</h3>
                        <p>
                            Lorsque vous utilisez notre formulaire de contact ou vous abonnez à notre newsletter, nous pouvons collecter les informations suivantes :

                            <ul>
                                <li>Nom complet</li>
                                <li>Adresse e-mail</li>
                                <li>Numero de telephone</li>
                                <li>Autres informations que vous choisissez de nous fournir dans votre message</li>
                            </ul>
                        </p>
                        <h3 className='font-bold mt-6 mb-4'>2.  Utilisation des Informations</h3>
                        <p>
                            Nous utilisons les informations que nous collectons pour les finalités suivantes :
                            <ul>
                                <li>Répondre à vos demandes soumises via le formulaire de contact.</li>
                                <li>Envoyer des mises à jour, des newsletters ou d'autres informations liées à nos activités, à condition que vous ayez choisi de vous abonner.</li>
                            </ul>
                        </p>
                        <h3 className='font-bold mt-6 mb-4'>3. Protection des Données</h3>
                        <p>
                            Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre toute utilisation non autorisée, perte ou accès non autorisé. Cependant, veuillez noter qu'aucune méthode de transmission de données sur Internet ou de stockage électronique n'est totalement sécurisée. Par conséquent, nous ne pouvons garantir la sécurité absolue de vos données.
                        </p>
                        <h3 className='font-bold mt-6 mb-4'>4. Partage d'Informations</h3>
                        <p>
                            Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers à des fins commerciales. Cependant, nous pouvons partager vos informations avec des prestataires de services tiers qui nous aident dans l'exploitation de notre Site, sous réserve de garantir qu'ils respectent également votre vie privée.
                        </p>
                        <h3 className='font-bold mt-6 mb-4'>4. Cookies et technologies similaires</h3>
                        <p>
                            Nous utilisons des cookies et des technologies similaires pour améliorer l'expérience de l'utilisateur sur notre Site. Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur.
                        </p>

                        <h3 className='font-bold mt-6 mb-4'>5. Vos droits</h3>
                        <p>
                            Vous avez le droit d'accéder, de corriger, de mettre à jour ou de supprimer les informations personnelles que nous détenons à votre sujet. Vous pouvez également retirer votre consentement à tout moment pour le traitement de vos informations. Pour exercer ces droits, veuillez nous contacter à l'aide des informations fournies sur la page de contact.
                        </p>
                        <h3 className='font-bold mt-6 mb-4'>6. Modifications de la Politique de Confidentialité</h3>
                        <p>
                            Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Toute modification prendra effet dès sa publication sur cette page. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.
                        </p>
                        <p className='mt-4'>
                            En utilisant notre Site, vous acceptez les termes de cette Politique de Confidentialité. Si vous avez des questions ou des préoccupations concernant cette politique, veuillez nous contacter à l'aide des informations fournies sur la page de contact.
                        </p>
                        <p className="mt-6 mb-4 mt-6">
                            Merci d'avoir visité le <a className="font-bold" href="http://wes.odacesoft.com">protefolio de Wilfried ETEKA</a>

                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
