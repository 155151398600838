import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom'
import $ from 'jquery';
import Footer from '../../components/Footer'
import ScrollToTop from '../../components/ScrollToTop';
import wil from "../../assets/eteka_wilfried_photo_profil.jpeg";
import "../../menu.css"
/*import logo1 from "../../assets/logos/logo1-removebg-preview.png";
import logo2 from "../../assets/logos/logo2-removebg-preview.png";
import logo3 from "../../assets/logos/logo3-removebg-preview.png";
*/
import { Link } from 'react-router-dom';
import Translator from '../../components/Translator';


function DefaultLayout() {
   const [darkMode, setDarkMode] = useState(false);
   //const [french, setFrench] = useState(true);

   useEffect(() => {
      //saveState('OK')
      const theme = loadState('mode');
      const themewindow = loadState('mode');
      setDarkMode(theme == '' ? themewindow : theme);      
      // const dlang = loadState('lang');
      // setFrench(dlang == 'en' ? false : true);

   });
   const loadState = (state) => {
      try {
         const serializedState = localStorage.getItem(state);
         if (serializedState === null) {
            return '';
         }
         return JSON.parse(serializedState);
      } catch (err) {
         return '';
      }
   };

   const saveState = (state,mode='mode') => {
      try {
         const serializedState = JSON.stringify(state);
         localStorage.setItem(mode, serializedState);
      } catch (err) {
         return '';
      }
   }
   function toggleDarkMode() {
      setDarkMode(darkMode => !darkMode);
      //alert(darkMode)
      saveState(!darkMode);
   }
   
   
   return (
      <div className={`main duration-100 bg-zinc-100 dark:bg-slate-800 h-full fixedoverflow-auto w-full  ${darkMode ? "dark" : ""}`}>
         <ScrollToTop />
         <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
         <Outlet />
         <Footer />
      </div>
   )
}
function Navbar({ darkMode, toggleDarkMode }) {
   const [showMenu, setShowMenu] = useState(false);
   const [french, setFrench] = useState(true);

   const location = useLocation();
   const { pathname } = location;
   const splitLocation = pathname.split("/");


   useEffect(()=>{
      
      const dlang = loadState('lang');
     // alert(dlang);
     // setFrench(dlang == 'en' ? false : true);
   })

   const ToggleMenu = () => {
      setShowMenu(!showMenu);
   }
   function toggleFr(lang='en') {
      setFrench(!french);
      //alert(darkMode)
      //saveState(lang);
      //gchange()
   }
   function gchange(){
     
      var lang = $(this).data('lang');
      var $frame = $('.VIpgJd-ZVi9od-ORHb-bN97Pc');
      if (!$frame.length) {
        alert("Error: Could not find Google translate frame.");
        return false;
      }
      $frame.contents().find('.VIpgJd-ZVi9od-xl07Ob-lTBxed span.text:contains('+lang+')').get(0).click();
      return false;
    
}
   const loadState = (state) => {
      try {
         const serializedState = localStorage.getItem(state);
         if (serializedState === null) {
            return '';
         }
         return JSON.parse(serializedState);
      } catch (err) {
         return '';
      }
   };

   const saveState = (state,mode='mode') => {
      try {
         const serializedState = JSON.stringify(state);
         localStorage.setItem(mode, serializedState);
      } catch (err) {
         return '';
      }
   }

   return (
      <div>
         <header className={`pointer-events-none relative z-50 flex py-3 flex-col`} id="top">
            <div className="top-0 z-10 h-16   justify-between items-center" >

               <div className="sm:px-8  top-[var(--header-top,theme(spacing.6))] w-full">
                  <div className="mx-auto max-w-6xl px-4">
                     <div className="absolute top-0 right-2">
                    
                     {/*<span id='translation-links'  className="relative block px-1 mt-0.5 py-2 transition-200 hover:text-teal-500 dark:hover:text-teal-400 flex">
                                       {french ?
                                      <img   data-google-lang="fr" onClick={toggleFr} id='fr' src="https://flagicons.lipis.dev/flags/4x3/fr.svg" alt="Fr" className='h-auto w-5 mx-1' data-google-lang="fr" /> 
                                      :
                                       <img  data-google-lang="eng" onClick={toggleFr} id="en" src="https://flagicons.lipis.dev/flags/4x3/gb.svg" alt="En" className='h-auto w-5' data-google-lang="en" /> 
                                       } 
                                    </span>*/}
                     </div>
                     <div className="relative">
                        <div className="mx-auto ">

                           <div className="relative flex gap-4">
                              <div className="">
                                 <Link aria-label="Home" className="block bg-white_  h-16w-16 origin-left pointer-events-auto" href="/">

                                    <img alt="Wilfried ETEKA" fetchpriority="high" width="512" height="512" loading='lazy' src={wil} decoding="async" data-nimg="1" className=" rounded-full bg-zinc-100 border-2 border-gray-100 shadow-md rounded-full object-cover dark:bg-zinc-800 h-16 w-16" sizes="4rem" />
                                 </Link>

                                   
                              </div>
                              <div className="flex flex-1 justify-end md:justify-center mt-3">
                                 <div
                                    className="pointer-events-auto md:hidden" data-headlessui-state="">
                                    <button onClick={ToggleMenu} className="group flex items-center rounded-full bg-white/90 px-4 py-2 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10 dark:hover:ring-white/20" type="button" aria-expanded="false" data-headlessui-state="" id="headlessui-popover-button-:R2qb6:">
                                       Menu
                                       <svg viewBox="0 0 8 6" aria-hidden="true" className={showMenu ? '-rotate-90 ml-3 h-auto w-2 stroke-zinc-500 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-400' : 'ml-3 h-auto w-2 stroke-zinc-500 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-400'}>
                                          <path d="M1.75 1.75 4 4.25l2.25-2.5" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                       </svg>
                                    </button>
                                 </div>
                                 <nav className={showMenu ? 'pointer-events-auto w-full md:w-auto transition-300 md:relative absolute top-14 md:top-0  left-0 flex-row md:block' : 'pointer-events-auto w-full md:w-auto  md:relative absolute top-14 md:top-0  left-0 flex-row hidden md:block'}>
                                    <ul className="md:flex flex-row rounded-lg py-4 md:py-0 md:rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10">
                                       <li><Link onClick={ToggleMenu} className={`relative block px-3 py-2 transition-200 hover:text-teal-500 dark:hover:text-teal-400 ${splitLocation[1] == "" ? 'text-teal-500' : ''}`} to="/">Accueil</Link></li>
                                       <li><Link onClick={ToggleMenu} className={`relative block px-3 py-2 transition-200 hover:text-teal-500 dark:hover:text-teal-400 ${splitLocation[1] == "articles" || splitLocation[1] == "article" ? 'text-teal-500' : ''}`} to="/articles">Articles</Link></li>
                                       <li><Link onClick={ToggleMenu} className={`relative block px-3 py-2 transition-200 hover:text-teal-500 dark:hover:text-teal-400 ${splitLocation[1] == "parcours" ? 'text-teal-500' : ''}`} to="/parcours">Parcours</Link></li>
                                       <li><Link onClick={ToggleMenu} className={`relative block px-3 py-2 transition-200 hover:text-teal-500 dark:hover:text-teal-400 ${splitLocation[1] == "projets" || splitLocation[1] == "projet" ? 'text-teal-500' : ''}`} to="/projets">Projets</Link></li>
                                       <li><Link onClick={ToggleMenu} className={`relative block px-3 py-2 transition-200 hover:text-teal-500 dark:hover:text-teal-400 ${splitLocation[1] == "contact" ? 'text-teal-500' : ''}`} to="/contact">Contact</Link></li>
                                      
                                    </ul>
                                 </nav>
                              </div>
                              <div className="flex relative justify-end md:flex-2 " >
                                
                                 <div className="pointer-events-auto">
                                 <button type="button"  aria-label="Toggle dark mode" className="group order-0 rounded-2 me-4 mt-2 bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20">
                                             <Translator />
                                    </button>
                                    <button type="button" onClick={toggleDarkMode} aria-label="Toggle dark mode" className="group rounded-full mt-2 bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20">
                                       {darkMode ?
                                          <svg viewBox="0 0 24 24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" className="h-6 w-6 fill-zinc-100 stroke-zinc-500 transition group-hover:fill-zinc-200 group-hover:stroke-zinc-700  [@media(prefers-color-scheme:dark)]:fill-teal-50 [@media(prefers-color-scheme:dark)]:stroke-teal-500 [@media(prefers-color-scheme:dark)]:group-hover:fill-teal-50 [@media(prefers-color-scheme:dark)]:group-hover:stroke-teal-600">
                                             <path d="M8 12.25A4.25 4.25 0 0 1 12.25 8v0a4.25 4.25 0 0 1 4.25 4.25v0a4.25 4.25 0 0 1-4.25 4.25v0A4.25 4.25 0 0 1 8 12.25v0Z"></path>
                                             <path d="M12.25 3v1.5M21.5 12.25H20M18.791 18.791l-1.06-1.06M18.791 5.709l-1.06 1.06M12.25 20v1.5M4.5 12.25H3M6.77 6.77 5.709 5.709M6.77 17.73l-1.061 1.061" fill="none"></path>
                                          </svg>
                                          :
                                          <svg viewBox="0 0 24 24" aria-hidden="true" className=" h-6 w-6 fill-zinc-700 stroke-zinc-500 transition  [@media(prefers-color-scheme:dark)]:group-hover:stroke-zinc-400 [@media_not_(prefers-color-scheme:dark)]:fill-zinc-100/10 [@media_not_(prefers-color-scheme:dark)]:stroke-zink-300">
                                             <path d="M17.25 16.22a6.937 6.937 0 0 1-9.47-9.47 7.451 7.451 0 1 0 9.47 9.47ZM12.75 7C17 7 17 2.75 17 2.75S17 7 21.25 7C17 7 17 11.25 17 11.25S17 7 12.75 7Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                          </svg>
                                       }
                                    </button>
                                    

                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>
      </div>
   )
}


export default DefaultLayout
