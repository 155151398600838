import React from 'react'
import AllArticles from '../components/AllArticles'

function Articles() {
  return (
    <div>
      <AllArticles />
    </div>
  )
}

export default Articles
