import React from 'react'

function LoadArticle({ nb }) {

    return (
        <>
            <div className="max-w-6xl  rounded my-12 mx-auto">
                <div className='mx-auto max-w-[800px] px-4 py-8 bg-white shadow-md rounded-md md:shadow-sm rounded-md dark:bg-gray-700 dark:border-gray-800 dark:hover:bg-gray-700/90 dark:text-zinc-300'>
                    <div role="status" className="space-y-8 mb-8 animate-pulse md:items-center">
                        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-600 w-full mb-4"></div>
                        <div className="h-4 bg-gray-200 mx-auto rounded-full dark:bg-gray-600 w-2/3 mb-4"></div>
                        <div className="h-2.5 w-8 mx-auto bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                    </div>
                    <div className="w-full items-center justify-center w-full mb-8 py-8  bg-gray-300 rounded  dark:bg-gray-600">
                        <svg className="w-full h-[300px]  text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>

                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <div className="col-md-12 mb-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <div className="col-md-12 mb-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default LoadArticle