import React from 'react';
import image404 from "../assets/img/404.png"

function Page404() {
  return (
    <div className='max-w-6xl bg-white shadow rounded my-12 mx-auto px-4 md:px-8 p-12 dark:bg-slate-700 dark:text-white'>
        <div className="grid grid-cols-12 items-center py-12 min-h-[200px]">
          <div className='col-span-3 dark:bg-white rounded-xl'>
            <img src={image404} alt="404 not found" />
          </div>
          <div className='col-span-9 px-8 '>
          <h1 className="text-6xl  font-bold">404, not found !</h1>
        <div className="text-leading-4 my-3 text-slate-600 dark:text-slate-200">
            La page que vous recherchez n'existe pas ou a été déplacée !
        </div>
          </div>
        </div>
    </div>
  )
}

export default Page404;
