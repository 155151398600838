import React from 'react'
import Works from './Works'
import NewletterCard from './NewletterCard'
import TopArticles from './TopArticles'
import Skills from './Skills'

function News() {
    return (
        <div className='max-w-6xl mx-auto px-4'>
            <div className="md:grid md:grid-cols-3 gap-8">
                <div className="col-span-2 ">
                    <TopArticles />
                </div>
                <div className="">
                    <NewletterCard />
                    <Works />
                    <Skills />
                </div>
            </div>
        </div>
    )
}

export default News
