
import React, { useState, useEffect } from 'react'
import moment from 'moment';
import frLocale from "../i18/momen-js";
import { Link, useParams } from 'react-router-dom';
import LoadList from '../components/loading/LoadList';
import NavParcours from '../components/NavParcours';
import Pagination from '../components/Pagination';
import useTitle from '../components/useTitle';
import { HTTP, PARCOURS } from '../datas/config';

function Certifications() {
    const [certifications, setCertif] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const {page=1} = useParams();
    //const [totalCertif, setTotalCertif] = useState(0);
    const [currentPage, setCurrentPage] = useState(page);
    const [lastPage, setLastPage] = useState(1);
    const [postsPerPage] = useState(20);
    const [url] = useState("/parcours/certifications/");
    
    
    let HTTP_API = PARCOURS.CERTIF ?PARCOURS.CERTIF:''; 
    let HTTP_HOME = HTTP.WEB ?HTTP.WEB:''; 
    
    useTitle('Certifications');
    moment.locale('fr', [frLocale]);

    const getCertifications = async (nb,page) => {
            setLoading(true);
            setCertif([]);
        if(page===undefined || page===NaN){
            page=1;
        }
        let API_URL = HTTP_API + '' + nb+"?page="+page;
        
        fetch(API_URL)
            .then(response => response.json())
            .then(json => {
                setCertif(json.data);
                setLastPage(json.last_page);
                //console.log(json.data)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                //setLoading(false);
            });
    };
    useEffect(() => {
        
        getCertifications(postsPerPage,page);
    }, []);
    const HandleChange=(p)=>{         
        setCurrentPage(p);
        getCertifications(postsPerPage,p);
    }
  return (
    <div className='mx-auto my-12 max-w-[800px]'>

            <NavParcours menu="certifications"/>

            <div className='mx-auto my-8 md:rounded-md shadow bg-white p-8 py-12 dark:border-gray-600 dark:bg-gray-700  dark:hover:bg-gray-700/90 dark:text-zinc-300'>
            {loading && (
                        <LoadList/>
                    )}
                <ol className="relative border-l border-gray-200 dark:border-gray-600">
                    {certifications && certifications.map((exp, index) => (
                        <li className="mb-10 ml-4" key={index}>
                            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-700"></div>
                            <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{exp.mois_emission+" "+exp.annee_emission } </time>
                            <div className="md:flex grid grid-cols-12 items-center justify-content-between md:gap-4 gap-2 font-semibold">
                                {exp.logo && 
                                <div className="logo col-span-3 overflow-hidden">
                                    <a target='_blanck' href={exp.site_web != '' ? exp.site_web : '#'}>
                                        <img alt={exp.ecole} src={HTTP_HOME + '' + exp.logo} loading="lazy" width="32" height="32" decoding="async" data-nimg="1" className="h-12 object-cover w-12 my-2" />
                                    </a>
                                </div>
                                 }
                                <div className="entreprise col-span-9">
                                    <dl className="flex flex-auto flex-wrap gap-x-2">
                                       
                                        <dd className="w-full flex-none text-sm font-medium text-zinc-600 dark:text-zinc-100">
                                         <h3 className="text-lg">  <a target='_blanck' href={exp.lien != '' ? exp.lien : '#'}>{exp.nom}</a></h3> 
                                        </dd>
                                        <dt className="sr-only">{exp.organisation}</dt>
                                        <dd className="text-sm text-zinc-500 dark:text-zinc-400"> <a target='_blanck' rel="noopener noreferrer" href={exp.lien}>{exp.organisation} </a> </dd>
                                        <dt className="sr-only"> </dt>
                                        <dd className="ml-auto text-xs text-zinc-400 dark:text-zinc-500" aria-label="2019 until Present"> </dd>
                                    </dl>
                                </div>
                            </div>
                            
                            <p className="mb-4 text-base text-sm_ font-normal text-gray-600 dark:text-slate-400" dangerouslySetInnerHTML={{ __html: exp.description && exp.description.replace(/(<? *script)/gi, 'illegalscript').replace(/(?:\r\n|\r|\n)/g, '<br />') }} >

                            </p>
                            <div className="grid md:grid-cols-2 overflow-hidden">
                                {exp.photo && <a target='_blanck' rel="noopener noreferrer" href={exp.lien}><img src={HTTP_HOME+""+exp.photo} alt={exp.nom} className='w-auto h-22' /></a>}
                            </div>

                        </li>)
                    )}
                </ol>

            <div className='pt-8'>
                <Pagination postsPerPage={postsPerPage} lastPage={lastPage} currentPage={currentPage}  url={url} onchange={HandleChange}  />
            </div>
            </div>
        </div>
  )
}

export default Certifications