import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { HTTP, PROJETS } from '../datas/config';
import LoadProjetCard from './loading/LoadProjetCard';


function GalerieProjets() {
    const page=1
    const [projets, setProjets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [postsPerPage] = useState(6);
    const [url] = useState('/projets/');
    
    let HTTP_API = PROJETS.ALL ?PROJETS.ALL:'';
    let HTTP_WEB = HTTP.WEB ?HTTP.WEB:'';

    const rows = [];
    for (let i = 1; i <= postsPerPage; i++) {
        rows.push(i);
    }
    useEffect(() => {         
            HandleChange(HTTP_API,postsPerPage,page);
    }, [page]);
    const HandlePageChange=(p)=>{
        
    }
    const HandleChange=(url,nb_page,current_page)=>{     
        setLoading(true);   
        fetch(url + '' + nb_page+"?page="+current_page)
                .then(response => response.json())
                .then(json => {
                    setLoading(false);
                    setProjets(json.data);
                })
                .catch(error => console.log(error));
        
        
    }

  return (
    <div className='p-4 md:px-8 shadow-inner bg-[#fafafa] 00 dark:shadow-inner dark:bg-zinc-600/10 dark:text-zinc-300 mt-8 mb-4 shadow'>
            <div className="mx-auto  max-w-6xl py-4   md:px-8">
          <div className="gridgrid-cols-12 item-center text-center">
             <h1 className='md:col-span-10 col-span-12 mb-4 text-3xl md:text-6xl text-uppercase text-center md:text-start_ font-bold'>Quelques projets</h1>
             <p className='text-zinc-400 '>Découvrez quelques projets sur lesquels j'ai travaillé</p>
              <div className=' md:col-span-2 col-span-12 text-center md:text-end text-center'>
                <Link to={'/projets'} className='text-slate-800 hover:text-teal-600 text-lg flex text-end gap-2 items-center justify-center my-6 dark:text-teal-600 py-2'> Tous les projets <FaArrowRight className='d-block' /></Link>
              </div>
             </div>
        <div className="">
        <div className="md:grid space-y-4 md:space-y-0 grid-cols-1 min-h-[300px]  md:grid-cols-2 lg:grid-cols-3 lg:grid-cols-3  gap-4 overflow-hidden py-4 sm:gap-4">
                {loading && rows && rows.map((nb, index)=>
                                <LoadProjetCard key={index} np={nb} />)
                                }
            {projets && projets.map((projet,index)=>(
              <div key={index} className="overflow-hidden">
                <a href={'projet/'+projet.slug}>
                  <img alt={projet.titre} title={projet.titre} src={HTTP_WEB+projet.image} loading='lazy'  decoding="async" data-nimg="1" className="relative  shadow rounded-md inset-0 h-full w-full object-cover  min-w-[200px]"  />
                </a>
            </div>
            ))}
        </div>
        </div>
        <div className="text-center md:text-start my-4 lg:my-8">
          <a className='bg-gray-900/70 btn  text-white/90 rounded-sm px-4 py-2' target='_blanck' href="https://github.com/eteka75?tab=repositories">
            Explorez sur <b>GitHub</b></a> 
             </div>
        </div>
        </div>
  )
}

export default GalerieProjets
