import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import {BiRightArrowCircle} from "react-icons/bi"
import CardArticle from './CardArticle';
import LoadCard from './loading/LoadCard';
import { ARTICLES } from '../datas/config';

function TopArticles() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true); 
    const nb_article=4;
    let API_URL = ARTICLES.ALL ?ARTICLES.ALL:''; 

    useEffect(() => {
        let URL=API_URL+`${nb_article}`;
        fetch(URL)
            .then(response => response.json())
            .then(json => {
                setLoading(false);
                setArticles(json.data);
                //console.log("DATA", json.data);
            })
            .catch(error => console.log(error));
    }, []);

    return (
        <div className='py-6'>
              {loading && <div>
                            <LoadCard />
                            <LoadCard />
                            <LoadCard />
                            <LoadCard />
                        </div>
                    }
            {articles && articles.map((article, index) => (
                <CardArticle article={article} index={index}  key={index}/>
            ))}
            <div className="my-4 lg:my-8 mx-auto">

                <Link className='btn flex flex-column  hover:text-slate-800  px-8 md:px-0 text-center md:text-start  dark:hover:text-slate-500 items-center gap-2 text-teal-600 hover:bg-slate-800hover:border-0hover:text-white font-bold borderborder-blue-700  py-2  rounded-md' to="/articles">Découvrir plus d'articles <BiRightArrowCircle /> </Link>
            </div>
        </div>
    )
}

export default TopArticles
