import React from 'react'
import NavParcours from '../components/NavParcours';
import useTitle from '../components/useTitle';
function AutresParcours() {
    useTitle('Langues, technologies maitrisées, hobies, ...');


    return (
        <div className='mx-auto my-12 max-w-[800px]'>

<NavParcours menu="autres" />

<div className='mx-auto my-8 md:rounded-md shadow bg-white p-8 py-12bdark:border-gray-600 dark:bg-gray-700  dark:hover:bg-gray-700/90 dark:text-zinc-300'>

    <ol className="relative border-l border-gray-200 dark:border-gray-600">

            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-slate-400 font-semibold">Langues</time>
                <div className="flex items-center justify-content-between gap-4 font-semibold">

                    <div className="infos mt-2">
                        <dl className="w-full flex-wrap gap-x-2">

                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3 flex gap-2"> <img src="https://flagicons.lipis.dev/flags/4x3/fr.svg" alt="Français" className='h-auto w-5' /> Français</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400">Bilingue  </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3 flex gap-2"><img src="https://flagicons.lipis.dev/flags/4x3/gb.svg" alt="Français" className='h-auto w-5' />  Anglais</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400"> Conversationnel </div>
                            </dd>
                        </dl>
                    </div>
                </div>



            </li>
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-700"></div>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-slate-400 font-semibold">Tehnologies</time>
                <div className="flex items-center justify-content-between gap-4 font-semibold">

                    <div className="infos">
                    <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3"> Frontend</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400">JavaScript, TypeScript, ReactJS, VueJS, Bootstrap, Ant Design, Tailwind CSS, Sass,
NuxtJS, NextJS, Apexchart, Chart.JS, ZingChart, Pjax, ElasticSearch, GraphQL,... </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3"> Backend</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400"> PHP, Laravel, Symfony, Slim, Node.js </div>
                            </dd>
                        </dl>
                        <dl className="w-full flex-wrap gap-x-2">

                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3">DevOps</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400">Docker, Kubernetes, AWS,WmWare, Virtualbox, Jenkins, ....  </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3"> Modélisation & Design</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400">UML, MERISE, Figma, Adobe Photoshop/ Illustrator  </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3">Base de données</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400"> MySQL, PostgreSQL, MongoDB, Neo4J  </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3">Agilité & Teams tools</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400"> SCRUM, Jira, Discord, Trello </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3">Test et gestion de codes</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400">Git, Jira, GitHub, GitLab, Bitbucket, JTest, PHPUnit  </div>
                            </dd>
                        </dl>
                        <dl className="w-full my-2 flex-wrap gap-x-2">
                            <dd className="grid grid-cols-12 gap-8 text-sm font-medium text-zinc-900 dark:text-zinc-100">
                             <h3 className="text-md col-span-12 md:col-span-3">Autres</h3>
                             <div className="text-sm text-zinc-500 col-span-12 md:col-span-9 font-normal  dark:text-slate-400">C#, C++, Java, Python,...  </div>
                            </dd>
                        </dl>

                    </div>
                </div>



            </li>
        </ol>

</div>
        </div>
    )
}

export default AutresParcours
