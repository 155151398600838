import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CardProjet from '../components/CardProjet';
import Pagination from '../components/Pagination';
import LoadProjetCard from '../components/loading/LoadProjetCard';
import { PROJETS } from '../datas/config';

//let HTTP_WEB = "http://127.0.0.1:8000/";

//import data from '../datas/data-article';
//let HTTP_API = "http://127.0.0.1:8000/api/v1/projets/";
//let nb_article=4;
function Projets() {
    let HTTP_API = PROJETS.ALL ? PROJETS.ALL : '';

    const { page = 1 } = useParams();
    const [projets, setProjets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPosts, setTotalPosts] = useState(0);
    const [currentPage, setCurrentPage] = useState(page);
    const [lastPage, setLastPage] = useState(1);
    const [postsPerPage] = useState(20);
    const [url] = useState('/projets/');

    let ldate = "";


    const rows = [];
    for (let i = 1; i <= postsPerPage; i++) {
        rows.push(i);
    }
    useEffect(() => {
        setCurrentPage(page);
        HandlePageChange(page);
    }, [page]);

    const HandlePageChange = (p) => {
        setCurrentPage(p);
        HandleChange(HTTP_API, postsPerPage, p);
    }

    const HandleChange = (url, nb_page, current_page) => {
        setLoading(true);
        fetch(url + '' + nb_page + "?page=" + current_page)
            .then(response => response.json())
            .then(json => {
                setLoading(false);
                setProjets(json.data);

                setTotalPosts(json.total);
                //setCurrentPage(json.from);
                setLastPage(json.last_page);

                //console.log(json)
            })
            .catch(error => console.log(error));


    }

    return (
        <div className=" py-12 ">
            <div className="mx-auto max-w-5xl px-4 xl:px-8">
                <div className="">
                    <div className="max-w-[800px] mx-auto">
                        <h1 className='font-bold text-xl dark:text-gray-300'>Projets</h1>
                        <div className="relative border-l ps-4 md:ps-8 my-8 border-gray-200 dark:border-gray-500">

                            <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
                                {loading && rows && rows.map((nb, index) =>
                                    <LoadProjetCard key={index} np={nb} />)}
                                {projets && projets.map((projet, index, arr) => {
                                    const previousItem = arr[index - 1] ? arr[index - 1] : null;
                                    ldate = previousItem ? previousItem.annee_debut : '-';
                                    return (<>
                                        {ldate != projet.annee_debut ?
                                            <div className='md:col-span-2'>
                                                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-700"></div>
                                                <div className="text-sm capitalize text-muted dark:text-slate-400 ">
                                                    {projet.annee_debut}
                                                </div>
                                            </div>
                                            : ''}
                                        <CardProjet projet={projet} index={index} key={index} />
                                    </>)

                                })
                                }
                            </div>
                            <div>
                                <Pagination url={url} postsPerPage={postsPerPage} lastPage={lastPage} currentPage={currentPage} onchange={HandlePageChange} totalPosts={totalPosts} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Projets
