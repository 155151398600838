import React from 'react';
import moment from 'moment';
import frLocale from "../i18/momen-js";
import { Link } from 'react-router-dom';
import { HTTP} from '../datas/config';

function CardArticle({article}) {
    let HTTP_WEB = HTTP.WEB ?HTTP.WEB:'';
    moment.locale('fr', [frLocale]);
     
  return (
    <article  className=" mb-4  ">
                        {article &&
                    <div className="mx-auto relative md:flex w-full border border-slate-100  md:border-slate-50 max-w-[800px] flex-row rounded-xl bg-white bg-clip-border text-gray-700 md:shadow-sm dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-700/90 dark:text-zinc-300">
                        {article.image && <div className="relative md:m-4 m-0 w-full md:w-5/12 shrink-0 overflow-hidden rounded-xl rounded-b-none md:rounded-b-xl  bg-white dark:bg-slate-600 bg-clip-border text-gray-700">
                            <Link to={`/article/` + article.slug}>
                            <img
                                    src={HTTP_WEB + article.image}
                                    alt="image"
                                    className="h-full w-full p-4 md:p-0 object-cover"
                                />
                            </Link>
                        </div>
                        }
                      <div className="p-6 md:pe-6 ps-4 ">
                            <h6 className="hidden mb-4 block font-sans text-base font-semibold uppercase leading-relaxed tracking-normal text-pink-500 antialiased">
                                startups
                            </h6>
                            <p className="text-xs  text-slate-400 ">{moment(article.created_at).format('D MMMM YYYY')}</p>
                            <h4 className="font-serifs hover:text-teal-600 mb-2 block text-xl font-bold leading-snug tracking-normal text-blue-gray-900 antialiased">
                                <Link to={`/article/` + article.slug}> {article.titre}</Link>
                            </h4>
                            <p className="mb-6 block font-sans text-base font-normal leading-relaxed text-gray-700  dark:text-zinc-400 antialiased">
                            <Link to={`/article/` + article.slug}> { article.resume &&  article.resume.substring(0,150)+"..." }</Link>
                            </p>
                            <Link className="inline-block" to={`article/` + article.slug}>
                                <button
                                    className="flex select-none items-center gap-2 rounded-lg  py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-teal-500 transition-all bg-teal-500/10 transition-200  hover:bg-teal-500/70 hover:text-white active:bg-teal-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    type="button"
                                >
                                    En savoir plus
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        className="h-4 w-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        ></path>
                                    </svg>
                                </button>
                            </Link>
                        </div>
                    </div>
                        }
                </article>
  )
}

export default CardArticle