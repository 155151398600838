import React from 'react'
import { Link } from 'react-router-dom';

export default function NavParcours({menu}) {
  return (
    
    <div className="text-sm font-medium text-center px-2 md:px-4 text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-start text-md">
                    
                    <li className="mr-4 md:mr-8 ">
                        <Link to="/parcours" className={menu=='experiences'?"inline-block py-4 text-black dark:text-slate-400 dark:border-teal-500 border-b-2 border-teal-500 rounded-t-lg  ":"inline-block py-4 dark:text-slate-500 dark:border-slate-500 hover:border-b-2 hover:border-teal-500 rounded-t-lg  "}
                         aria-current="page">Expériences</Link>
                    </li>
                    <li className="mr-4 md:mr-8 ">
                        <Link to="/parcours/formations" className={menu=='formations'?"dark:text-slate-400 dark:border-teal-500  inline-block py-4 text-black border-b-2 border-teal-500 rounded-t-lg  ":"inline-block py-4 dark:text-slate-500 dark:border-slate-500 hover:border-b-2 hover:border-teal-500 rounded-t-lg"}>Formations</Link>
                    </li>
                    <li className="mr-4 md:mr-8 ">
                        <Link to="/parcours/certifications" className={menu=='certifications'?"dark:text-slate-400 dark:border-teal-500  inline-block py-4 text-black border-b-2 border-teal-500 rounded-t-lg  ":"inline-block py-4 dark:text-slate-500 dark:border-slate-500 hover:border-b-2 hover:border-teal-500 rounded-t-lg"}>Certifications</Link>
                    </li>
                    <li className="mr-4 md:mr-8 ">
                        <Link to="/parcours/autres" className={menu=='autres'?"dark:text-slate-400 dark:border-teal-500  inline-block py-4 text-black border-b-2 border-teal-500 rounded-t-lg  ":"inline-block py-4 dark:text-slate-500 dark:border-slate-500 hover:border-b-2 hover:border-teal-500 rounded-t-lg"}>Autres </Link>
                    </li>
                    
                </ul>
            </div>
  )
}
