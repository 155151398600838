import React from 'react';
import useTitle from '../components/useTitle';


function Terms() {
    useTitle("Conditions d'utilisations");
    return (
        <>
            <div className="max-w-6xl  my-12 mx-auto">
                <div className='mx-auto max-w-[800px] border border-zinc-50 p-4 md:p-10 bg-white md:rounded-md md:shadow-sm dark:bg-gray-700 dark:border-gray-700  dark:text-zinc-300'>
                    <h1 className='font-light text-2xl mb-4 dark:text-gray-300'>Conditions d'utilisations</h1>
                    <div className="text-lg">
                        <p className='mb-6'>
                            <strong> Dernière mise à jour : </strong> 10 Août 2022
                        </p>
                      <p>
                      Bienvenue sur <a className='font-bold text-teal-500' href="https://wes.odacesoft.com">https://wes.odacesoft.com</a> (ci-après dénommé "le protefolio de Wilfried ETEKA"). </p>
                      <p className='my-4'>Avant de parcourir et d'utiliser ce site, veuillez lire attentivement les présentes Conditions d'Utilisation. En accédant ou en utilisant le Site, vous acceptez d'être lié par ces conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser le Site.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>1. Contenu</h3>
                      <p>
                      Le contenu de ce site web est à titre informatif et éducatif uniquement. Les informations fournies ici sont basées sur les connaissances et l'expérience de [VotreNom] et ne doivent pas être considérées comme des conseils professionnels. Nous ne sommes pas responsables des actions entreprises en se basant sur les informations fournies sur ce site.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>2. Propriété Intellectuelle</h3>
                      <p>
                      Le contenu original, les fonctionnalités et la conception de ce site web sont protégés par le droit d'auteur et d'autres lois sur la propriété intellectuelle et demeurent la propriété exclusive de [VotreNom] et de ses concédants de licence. La reproduction, la distribution ou la modification du contenu sans autorisation préalable est strictement interdite.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>3. Abonnement à la Newsletter</h3>
                      <p>
                      Si vous choisissez de vous abonner à la newsletter du Site, vous acceptez de recevoir des mises à jour régulières par e-mail. Vous pouvez vous désabonner à tout moment en suivant les instructions de désabonnement fournies dans chaque e-mail.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>4. Utilisation de Commentaires et de Contributions</h3>
                      <p>
                      Lorsque vous publiez des commentaires ou contribuez au contenu de ce site, vous accordez à [VotreNom] une licence mondiale, non exclusive, libre de redevances pour utiliser, reproduire, modifier et distribuer vos commentaires dans tous les formats connus ou à développer.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>5. Liens vers des sites tiers</h3>
                      <p>
                      Le Site peut contenir des liens vers des sites Web tiers. Ces liens sont fournis uniquement à titre de commodité. Nous n'avons aucun contrôle sur le contenu de ces sites tiers et déclinons toute responsabilité quant à leur contenu, leur exactitude ou leur disponibilité.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>6. Contact et confidentialité</h3>
                      <p>
                      Le Site propose un formulaire de contact permettant aux utilisateurs de vous contacter directement. En utilisant ce formulaire, vous acceptez que les informations que vous fournissez puissent être utilisées pour répondre à votre demande et pour d'autres communications liées à votre demande. Veuillez consulter notre Politique de Confidentialité pour en savoir plus sur la manière dont nous traitons vos données personnelles.
                      </p>
                      <p>
                      Nous nous réservons le droit de modifier ou de remplacer ces Conditions à tout moment. Il est de votre responsabilité de vérifier régulièrement cette page pour les mises à jour. Votre utilisation continue ou l'accès au Service après la publication de toute modification constitue une acceptation de ces modifications.
                      </p>
                      <h3 className='font-bold mt-6 mb-4'>7. Limitation de Responsabilité</h3>
                      <p>
                      Dans la mesure maximale autorisée par la loi applicable, nous déclinons toute responsabilité en ce qui concerne les dommages directs, indirects, consécutifs, spéciaux ou exemplaires découlant de l'utilisation du Site ou de l'incapacité à l'utiliser.
                      </p>
                      <p>
                      En utilisant ce Site, vous acceptez de respecter ces Conditions d'Utilisation. Si vous avez des questions ou des préoccupations concernant ces conditions, veuillez nous contacter à l'aide des informations fournies sur la page de contact.
                      </p>
                      <p className="mt-6 mb-4 mt-6">
                      Merci d'avoir visité le <a className="font-bold" href="http://wes.odacesoft.com">protefolio de Wilfried ETEKA</a>

                      </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Terms;
