
import React, { useState, useEffect } from 'react';
import LoadCard from '../components/loading/LoadCard';
import CardArticle from '../components/CardArticle';
import MiniCardArticle from '../components/MiniCardArticle';
import { Link, useParams } from 'react-router-dom';
import CardProjet from '../components/CardProjet';
import { FaArrowRight } from 'react-icons/fa';
import { PROJETS } from '../datas/config';


export default function  RecommandationProjets({idProj}) {
    const [articles, setArticle] = useState([]);
    const [loading, setLoading] = useState(true);
    const { slug } = useParams();

  let HTTP_API = PROJETS.RECOM ?PROJETS.RECOM:'';

  
    useEffect(() => {
      const getPage = async (nb) => {
        
        let API_URL = `${HTTP_API}${nb}`;
        fetch(API_URL)
          .then(response => response.json())
          .then(json => {
            setLoading(false);
            setArticle(json);
            //console.log("ARTICLES",json);
          }).catch((error) => {
            console.log(error);
            setLoading(false);
          })
          .catch(error => console.log(error));
      };
  
      getPage(6);
    }, [slug]);
    return (
      <>
        <div className="bg-zinc-200 py-1 dark:border-gray-800 dark:bg-gray-700/40  dark:text-zinc-300">
          <div className="max-w-6xl  rounded my-12 mx-auto">
            {loading &&
              <div className='grid gap-8  grid-cols-1 md:grid-cols-2  xl:grid-cols-3'>
                <LoadCard />
                <LoadCard />
                <LoadCard />
              </div>
            }
            {!loading && articles &&
              <div className='px-4 px-md-8'>
                 <div className="grid grid-cols-12 item-center  ">
             <h3 className='md:col-span-10 col-span-12 mb-4 font-bold text-center md:text-start'>Autres projets </h3>
              <div className=' md:col-span-2 col-span-12 text-center md:text-end text-center'>
                <Link to={'/projets'} className='text-slate-500 hover:text-teal-600 text-sm flex text-end gap-2 items-center justify-center md:justify-end mb-4 md:mb-0 py-2'> Tous les projets <FaArrowRight /></Link>
              </div>
             </div>
                <div className='grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:mt-4'>
  
                  {articles.map((projet, index) => 
                        {
                            if(projet && projet.id!=idProj){
                                return <CardProjet projet={projet} index={index} key={index} />
                            }
                        }
                  )
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </>
  )
}



