import React, { useState, useEffect } from 'react'
import {  FaGlobe, FaLocationArrow, FaMapMarked,  FaPlug, FaRoad } from "react-icons/fa";
import moment from 'moment';
import frLocale from "../i18/momen-js";
import { Link } from 'react-router-dom';
import LoadList from '../components/loading/LoadList';
import NavParcours from '../components/NavParcours';
import useTitle from '../components/useTitle';
import { HTTP, PARCOURS } from '../datas/config';

function Parcours() {
    const [experiences, setExp] = useState([]);
    const [loading, setLoading] = useState(true);
    useTitle('Expériences');
    
    let HTTP_API = PARCOURS.ALL ?PARCOURS.ALL:''; 
    let HTTP_HOME = HTTP.WEB ?HTTP.WEB:''; 

    moment.locale('fr', [frLocale]);

    useEffect(() => {
        const getParcours = async (nb) => {
            let API_URL = `${HTTP_API}${nb}`;
            fetch(API_URL)
                .then(response => response.json())
                .then(json => {
                    setExp(json.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    //setLoading(false);
                });
        };

        getParcours(100);
    }, []);
    return (
        <div className='mx-auto my-12 max-w-[800px]'>

            <NavParcours menu="experiences"/>

            <div className='mx-auto my-8 md:rounded-md md:shadow bg-white p-8 py-12 dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-700/90 dark:text-zinc-300'>
                
                    {loading && (
                        <LoadList/>
                    )}
                <ol className="relative border-l  border-gray-200 dark:border-gray-500">
                    {experiences && experiences.map((exp, index) => (
                        <li className="mb-10 ml-4" key={index}>
                            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-700"></div>
                            <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-slate-400">{moment(exp.date_debut).format('MMMM YYYY')}{exp.date_fin && " — " + moment(exp.date_fin).format('MMMM YYYY')}</time>
                            <div className="text-sm text-slate-400 flex md:float-right "><FaMapMarked className='my-1 mx-1' /> {exp.lieu}</div>
                            <div className="grid grid-cols-12 w-full md:flex items-center justify-content-between gap-4 font-semibold">
                                <div className="logo col-span-4">
                                    <a target='_blanck' href={exp.site_web != '' ? exp.site_web : '#'}>
                                        <img alt={exp.entreprise} src={HTTP_HOME + '' + exp.logo} loading="lazy"  decoding="async" data-nimg="1" className="md:h-12 object-cover md:w-12 my-2  img-fluid" />
                                    </a>
                                </div>
                                <div className="entreprise col-span-8">
                                    <dl className="flex flex-auto flex-wrap gap-x-2">
                                        <dt className="sr-only">
                                            <a target='_blanck' href={exp.site_web != '' ? exp.site_web : '#'}>{exp.entreprise}</a>
                                        </dt>
                                        <dd className="w-full flex-none md:text-sm font-medium text-zinc-900 dark:text-zinc-100"><Link target='_blanck' to={exp.site_web ? exp.site_web : '#'}>{exp.entreprise} </Link></dd>
                                        <dt className="sr-only">{exp.poste} </dt>
                                        <dd className="md:text-sm text-zinc-500 dark:text-slate-400">{exp.poste} </dd>
                                        <dt className="sr-only">{exp.date_debut} </dt>
                                    </dl>
                                </div>
                            </div>
                            <h3 className="text-ms font-semibold text-gray-900 dark:text-white">{exp.role}</h3>
                            <div className="gridgrid-cols-5 md:flex space-y-4 md:space-y-0 sm:flex-rows md:flex-column gap-4  rounded-sm my-2 py-1 md:text-xs">
                                {exp.secteur && <div className='flex text-slate-400 gap-1  items-center'>
                                    <FaRoad />
                                    <span>{exp.secteur}</span>
                                </div>}
                                {exp.type_lieu && <div className='flex text-slate-400 gap-1  items-center'>
                                    <FaLocationArrow />
                                    <span>{exp.type_lieu}</span>
                                </div>}
                                {exp.type_emploi && <div className='flex text-slate-400 gap-1  items-center'>
                                    <FaPlug />
                                    <span>{exp.type_emploi}</span>
                                </div>}
                                {exp.site_web && <div className='flex text-slate-400 gap-1  items-center'>
                                    <FaGlobe className='text-teal-500' />
                                    <span> <a href={exp.site_web} className='text-teal-500' target="_blank" rel="noopener noreferrer">{exp.site_web}</a></span>
                                </div>}
                            </div>
                            <p className="mb-4 text-base text-sm_ font-normal text-gray-900 dark:text-slate-400" dangerouslySetInnerHTML={{ __html: exp.description && exp.description.replace(/(<? *script)/gi, 'illegalscript').replace(/(?:\r\n|\r|\n)/g, '<br />') }} >

                            </p>

                        </li>)
                    )}
                </ol>

            </div>
        </div>
    )
}

export default Parcours