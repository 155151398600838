import React, {useState,useEffect} from 'react';

import { useParams } from "react-router-dom";
import LoadArticle from '../components/loading/LoadArticle';
import useTitle from '../components/useTitle';
import ShowProjet from '../components/ShowProjet';
import RecommandationProjets from '../components/RecommandationProjets';
import { PROJETS } from '../datas/config';



function Projet() {
    const [article, setArticle] = useState("");
    const {slug="-"} = useParams();
    const [loading, setLoading] = useState(true);

    let HTTP_API = PROJETS.SHOW ?PROJETS.SHOW:'';
    
    useEffect(() => {
        const getPage = async (url) => {
          setLoading(true);
            let API_URL=`${HTTP_API}${url}`;
            fetch(API_URL)
            .then(response => response.json())
            .then(json => {
              setLoading(false);
              setArticle(json);
            })
            .catch(error =>  console.log(error));
        };
        
        getPage(slug);
      }, [slug]);
      useTitle(article?article.titre:"Article");
  return (
    <>
    {loading && <LoadArticle />}
       {!loading && article &&  (
        <>
          <ShowProjet data={article} />
        </>
       )}
       
      <RecommandationProjets idProj={article?article.id:0} />
    </>
  )
}

export default Projet;
