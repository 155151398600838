import React from 'react'

export default function LoadList() {
    return (
        <>
            <ol className="relative border-l animate-pulse border-gray-200 dark:border-gray-600 ">
                <li className="mb-10 ml-4 pt-1" >
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-600"></div>

                    <div className="h-2 bg-gray-200 mt-2 w-64 rounded-full dark:bg-gray-600 mb-2.5"></div>
                    <div className="h-2 w-2/5 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                    <div className="h-2 w-4/5 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>

                    <div className="flex items-center justify-content-between gap-4 font-semibold">
                        <div className="grid grid-cols-12 mb-4 gap-4">
                            <div className='col-span-2'>
                                <div className="h-[50px] w-[50px]  rounded-full bg-gray-200 w-64 rounded-full dark:bg-gray-600"></div>
                            </div>
                            <div className='col-span-9'>
                                <div className="h-2 w-60 bg-gray-200 mt-4 rounded-full dark:bg-gray-600 mb-2.5"></div>
                                <div className="h-2 w-4/5 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            </div>
                        </div>
                    </div>

                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                    <div className="h-2 w-3/8 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>

                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-600"></div>
                    
                    <div>
                        <div className="w-full mt-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <span className="sr-only">Chargement...</span>
                    </div>
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-600"></div>

                    <div>
                        <div className="w-full mt-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <span className="sr-only">Chargement...</span>
                    </div>
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-600"></div>

                    <div>
                        <div className="w-full mt-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <span className="sr-only">Chargement...</span>
                    </div>
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-600"></div>

                    <div>
                        <div className="w-full mt-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <span className="sr-only">Chargement...</span>
                    </div>
                    <div>
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-600"></div>

                        <div className="w-full mt-8">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-600 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[480px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[440px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[460px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-600 max-w-[360px]"></div>
                        </div>
                        <span className="sr-only">Chargement...</span>
                    </div>

                </li>
            </ol>
        </>
    )
}
