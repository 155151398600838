import React from 'react'

export default function LoadMiniExp() {
    return (
        <div className='loading animate-pulse'>
            <div className="grid grid-cols-12 mb-4 gap-4">
                <div className='col-span-1 md:col-span-3'>
                    <div className="h-[50px] w-[50px]  rounded-full bg-gray-200 w-64 rounded-full dark:dark:bg-gray-600"></div>
                </div>
                <div className='col-span-10 md:col-span-8 my-1 my-md-0'>
                    <div className="h-2 w-full bg-gray-200 mt-2 rounded-full dark:bg-gray-600 mb-2.5"></div>
                    <div className="h-2 w-3/5 bg-gray-200 rounded-full dark:dark:bg-gray-600 mb-2.5"></div>
                </div>
            </div>
        </div>
    )
}
