import React from 'react';
import Typed from 'react-typed';

import wildebout from "../assets/eteka_wilfried_debout.jpg";
import { FaArrowRight } from 'react-icons/fa';


function Hero() {
  return (
    <div className=" pt-12 ">
   <div className="mx-auto max-w-7xl xl:px-8">
      <div className="relative px-4 lg:px-12">
         <div className="mx-auto">
            <div className="md:grid  dark:text-zinc-300 md:grid-cols-2 transition-300 gap-8">
            <div className="max-w-2xl">
               <h1 className="text-3xl text-center md:text-start font-bold tracking-tight text-zinc-800_  md:text-4xl lg:text-5xl xl:text-5xl font-extrabold text-transparent  bg-clip-text bg-gradient-to-r from-teal-700 to-sky-500 text-shadow">
                  Expert en Développement Web, Design et Formateur
                  <div className="md:text-4xl sm:text:3xl text-2xl font-bold  mt-2 text-slate-400 d-inline">[<Typed className='' loop  strings={["PHP","JavaScript","Vue.JS","React.js","MySQL","MongoDB","Git","Jira","UI/UX","UML","Figma", "Photoshop","Java","PostgreSQL","API RESFull"]} typeSpeed={120} backSpeed={140} />]</div>

                </h1>
               <div className="mt-6 text-lg text-zinc-700 dark:text-zinc-100 text-center md:text-start">
                  <div className="text-lg font-bold "> Bienvenu cher(e) Ami(e) ! </div>Je suis Wilfried S. ETEKA,  ingénieur en informatique et <b>passioné du Design, du Web, des métiers du Web et de l'esploration spaciale.</b> 
                  <p className='hidden'>J'ai eu l'occasion de travailler sur une vaste gamme de projets, allant des sites web responsives et interactifs aux identités visuelles uniques pour des marques diverses. </p>
                </div>
               <p className='lead font-bold py-2 hidden sm:d-block dark:text-zinc-400 hidden'>Chaque projet est pour moi une opportunité de repousser les limites de ma créativité et de mon savoir-faire technique.</p>
                <div className='mb-3 text-lg text-gray-700  md:text-xl dark:text-slate-400p-4 my-4 border-l-4border-gray-300bg-gray-50 dark:border-gray-500  dark:text-slate-200 mt-4'> Que vous soyez une entreprise cherchant à <b> améliorer votre processus de digitalisation ou de présence en ligne</b>, une startup en quête d'une <b>stratégie gagnante</b>,...cela fait partie de mes cordes.
               
                
               
                </div>
               
               <div className='text-slate-900 text-md dark:text-white-100 text-center md:text-start dark:text-slate-400 '>J'aime les belles aventures 🤗. Et si on commençait une toute nouvelle aventure ? Mais, avant discutons-en...With Love ❤️</div>
              
               <div className='flex flex-column text-center md:text-start md:my-2 items-center justify-center md:justify-start my-4 '>
                  <a href="https://calendly.com/etekawilfried/30min" className='px-4 btn text-sm border bg-teal-500 dark:bg-teal-100/10 dark:border-teal-500 py-3 mt-4 text-white hover:bg-teal-100 dark:hover:bg-teal-500 dark:text-white text-teal-500 rounded-md flex gap-2 items-center justify-center md:justify-start' target="_blank" rel="noopener noreferrer">Prendre rendez-vous <FaArrowRight className='d-inline '/></a>
               </div>
               
            </div>
            <div className='col-md-6 items-center mb-8 md:mb-0'>
                <img src={wildebout}  className='transition-all duration-300 cursor-pointer my-8 md:my-4 filter grayscale hover:grayscale-0 h-full my-md-4 md:w-[300px] w-4/5 object-cover object-right-top max-h-[500px]  mx-auto rounded-3 shadow-lg rotate-[5deg] rounded-md  bg-[#eeeeee]' alt="Image de Wilfried eETEKA" />
            
                <div className="my-8 text-center md: flex justify-center  gap-6">
               <div className='text-slate-500'>
                  Restons connecté : 
               </div>
               <div className='flex gap-2'>
               <a className="group -m-1 p-1" target='_blanck' aria-label="Follow on LinkedIn" href="https://www.linkedin.com/in/eteka-wilfried/">
                     <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 fill-zinc-500 transition group-hover:fill-blue-600 dark:fill-zinc-400 dark:group-hover:fill-sky-700">
                        <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
                     </svg>
                  </a>
                  <a className="group -m-1 p-1"  target='_blanck' aria-label="Follow on Twitter" href="https://twitter.com/@wilfried_eteka">
                     <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 fill-zinc-500 transition group-hover:fill-sky-500 dark:fill-zinc-400 dark:group-hover:fill-sky-300">
                        <path d="M20.055 7.983c.011.174.011.347.011.523 0 5.338-3.92 11.494-11.09 11.494v-.003A10.755 10.755 0 0 1 3 18.186c.308.038.618.057.928.058a7.655 7.655 0 0 0 4.841-1.733c-1.668-.032-3.13-1.16-3.642-2.805a3.753 3.753 0 0 0 1.76-.07C5.07 13.256 3.76 11.6 3.76 9.676v-.05a3.77 3.77 0 0 0 1.77.505C3.816 8.945 3.288 6.583 4.322 4.737c1.98 2.524 4.9 4.058 8.034 4.22a4.137 4.137 0 0 1 1.128-3.86A3.807 3.807 0 0 1 19 5.274a7.657 7.657 0 0 0 2.475-.98c-.29.934-.9 1.729-1.713 2.233A7.54 7.54 0 0 0 22 5.89a8.084 8.084 0 0 1-1.945 2.093Z"></path>
                     </svg>
                  </a>
                  <a className="group -m-1 p-1"  target='_blanck' aria-label="Follow on Instagram" href="https://instagram.com/wilzuckerberg">
                     <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 fill-zinc-500  transition group-hover:fill-rose-500 dark:fill-zinc-400 dark:group-hover:fill-zinc-100">
                        <path d="M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.185.418A4.412 4.412 0 0 0 4.51 4.511c-.5.5-.809 1.002-1.039 1.594-.222.572-.374 1.226-.418 2.184C3.01 9.25 3 9.556 3 12s.01 2.75.054 3.71c.044.959.196 1.613.418 2.185.23.592.538 1.094 1.039 1.595.5.5 1.002.808 1.594 1.038.572.222 1.226.374 2.184.418C9.25 20.99 9.556 21 12 21s2.75-.01 3.71-.054c.959-.044 1.613-.196 2.185-.419a4.412 4.412 0 0 0 1.595-1.038c.5-.5.808-1.002 1.038-1.594.222-.572.374-1.226.418-2.184.044-.96.054-1.267.054-3.711s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.419-2.185A4.412 4.412 0 0 0 19.49 4.51c-.5-.5-1.002-.809-1.594-1.039-.572-.222-1.226-.374-2.184-.418C14.75 3.01 14.444 3 12 3Zm0 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.187 1.67.31.421.163.72.358 1.036.673.315.315.51.615.673 1.035.123.317.27.794.31 1.671.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.877-.187 1.354-.31 1.67-.163.421-.358.72-.673 1.036a2.79 2.79 0 0 1-1.035.673c-.317.123-.794.27-1.671.31-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.187-1.67-.31a2.789 2.789 0 0 1-1.036-.673 2.79 2.79 0 0 1-.673-1.035c-.123-.317-.27-.794-.31-1.671-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.67.163-.421.358-.72.673-1.036.315-.315.615-.51 1.035-.673.317-.123.794-.27 1.671-.31.95-.043 1.234-.052 3.637-.052Z"></path>
                        <path d="M12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-7.622a4.622 4.622 0 1 0 0 9.244 4.622 4.622 0 0 0 0-9.244Zm5.884-.182a1.08 1.08 0 1 1-2.16 0 1.08 1.08 0 0 1 2.16 0Z"></path>
                     </svg>
                  </a>
                  <a className="group -m-1 p-1"  target='_blanck' aria-label="Follow on GitHub" href="https://github.com/eteka75">
                     <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 fill-zinc-500 transition group-hover:fill-gray-400 dark:fill-zinc-400 dark:group-hover:fill-slate-300">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.475 2 2 6.588 2 12.253c0 4.537 2.862 8.369 6.838 9.727.5.09.687-.218.687-.487 0-.243-.013-1.05-.013-1.91C7 20.059 6.35 18.957 6.15 18.38c-.113-.295-.6-1.205-1.025-1.448-.35-.192-.85-.667-.013-.68.788-.012 1.35.744 1.538 1.051.9 1.551 2.338 1.116 2.912.846.088-.666.35-1.115.638-1.371-2.225-.256-4.55-1.14-4.55-5.062 0-1.115.387-2.038 1.025-2.756-.1-.256-.45-1.307.1-2.717 0 0 .837-.269 2.75 1.051.8-.23 1.65-.346 2.5-.346.85 0 1.7.115 2.5.346 1.912-1.333 2.75-1.05 2.75-1.05.55 1.409.2 2.46.1 2.716.637.718 1.025 1.628 1.025 2.756 0 3.934-2.337 4.806-4.562 5.062.362.32.675.936.675 1.897 0 1.371-.013 2.473-.013 2.82 0 .268.188.589.688.486a10.039 10.039 0 0 0 4.932-3.74A10.447 10.447 0 0 0 22 12.253C22 6.588 17.525 2 12 2Z"></path>
                     </svg>
                  </a>
                  </div>
                  
               </div>
            </div>
            </div>
            <div className="col-md-12 mt-12">
            <div className="md:p-8 p-6 text-center md:text-start bg-white shadow dark:bg-zinc-500/10 my-4 rounded-md ">
               <blockquote className="text-xl italic font-semibold text-gray-900">
               <svg className="w-8 h-8 text-gray-400 mx-auto md:mx-0 dark:text-gray-200 mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                     <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
               </svg>
                  <p className='text-3xl xl:text-5xl mb-2 mb-xl-4 font-extrabold text-slate-700 dark:text-zinc-300 '>Pour être irremplaçable, vous devez être différent.</p>
               <small className="text-slate-700 text-xs dark:text-zinc-400">- Wilfried ETEKA</small>
               </blockquote>
          </div>
            </div>
         </div>
      </div>
   </div>
</div>
  )
}

export default Hero
