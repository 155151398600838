import React from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';
import frLocale from "../i18/momen-js";
import { HTTP } from '../datas/config';

function MiniCardArticle({article}) {
    moment.locale('fr', [frLocale]);
    
    let HTTP_WEB = HTTP.WEB ?HTTP.WEB:'';


  return (
    <>
        
                        {article &&
                    <article className="bg-white border border-slate-50 shadow-sm  rounded-md mx-auto relative flex w-full  max-w-[800px] flex-row  bg-white bg-clip-border text-gray-700 dark:bg-gray-700 dark:border-gray-600 dark:text-slate-300 dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-700/70 ">
                        {article.image && <div className="relative m-0 w-4/12 shrink-0 overflow-hidden rounded-md rounded-r-none bg-white bg-clip-border text-gray-700 dark:bg-gray-700 dark:border-gray-700 ">
                            <Link to={`/article/` + article.slug}>
                                <img
                                    src={HTTP_WEB + article.image}
                                    alt="image"
                                    className="h-full w-full object-cover"
                                />
                            </Link>
                        </div>
                        }
                      <div className="px-4 py-4">
                            
                            <p className="text-xs  text-slate-400">{moment(article.created_at).format('D MMMM YYYY')}</p>
                            <h4 className="font-serifs mb-2 block  font-semibold leading-snug tracking-normal text-blue-gray-900 dark:hover:text-white -100 antialiased">
                                <Link className='hover:text-teal-600' to={`/article/` + article.slug}> {article.titre}</Link>
                            </h4>
                           
                            
                        </div>
                </article>
                        }
    </>
  )
}


export default MiniCardArticle