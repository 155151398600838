import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Page404 from '../components/Page404'

function Notfound() {
  return (
    <div>
       <Page404 />
    </div>
  )
}

export default Notfound
