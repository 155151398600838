import React from 'react'
import Hero from '../components/Hero'
import GalerieProjets from '../components/GalerieProjets'
import News from '../components/News'

function Index() {
  return (
    <div>
        <Hero />
        <GalerieProjets/>
        <News />
    </div>
  )
}

export default Index
