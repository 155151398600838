import React, {useState,useEffect} from 'react';

import ShowArticle from '../components/ShowArticle'
import { useParams } from "react-router-dom";
import LoadArticle from '../components/loading/LoadArticle';
import Recommandations from './Recommandations';
import useTitle from '../components/useTitle';
import { ARTICLES} from '../datas/config';


let HTTP_API="http://127.0.0.1:8000/api/v1/article";


function Article() {
    const [article, setArticle] = useState("");
    const {slug} = useParams();
    const [loading, setLoading] = useState(true);
    
    let HTTP_API = ARTICLES.SHOW ?ARTICLES.SHOW:'';
    
    useEffect(() => {
      const getPage = async (url) => {
        setLoading(true);
        let API_URL=`${HTTP_API}${url}`;
            fetch(API_URL)
            .then(response => response.json())
            .then(json => {
              setLoading(false);
              setArticle(json);
            })
            .catch(error =>  console.log(error));
        };
        
        getPage(slug);
      }, [slug]);
      useTitle(article?article.titre:"Article");
  return (
    <>
    {loading && <LoadArticle />}
       {!loading && article &&  (
        <>
          <ShowArticle data={article} />
        </>
       )}
       
      <Recommandations aId={article?article.id:0} />
    </>
  )
}

export default Article;
