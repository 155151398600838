import React, { useState, useEffect } from 'react'
import CardArticle from './CardArticle';
import LoadCard from './loading/LoadCard';
import Pagination from './Pagination';
import { useParams } from 'react-router-dom';
import useTitle from './useTitle';
import moment from 'moment';
import frLocale from "../i18/momen-js";
import { ARTICLES} from '../datas/config';

function AllArticles() {
    let HTTP_API = ARTICLES.ALL ?ARTICLES.ALL:'';

    const {page=1} = useParams(1);
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPosts, setTotalPosts] = useState(0);
    const [currentPage, setCurrentPage] = useState(page);
    const [lastPage, setLastPage] = useState(1);
    //const [currentDate,setCurrentDate] = useState("");
    const [postsPerPage] = useState(12);
    
    moment.locale('fr', [frLocale]);
    
    const [url] = useState('/articles/');

    useTitle('Articles');
    const rows = [];
    for (let i = 1; i <= postsPerPage; i++) {
        rows.push(i);
    }
    useEffect(() => {     
            setCurrentPage(page);      
            HandleChange(HTTP_API,postsPerPage,page);
    }, [page]);
    const HandlePageChange=(p)=>{
        setCurrentPage(p);
        HandleChange(HTTP_API,postsPerPage,p);
    }
    const HandleChange=(url,nb_page,current_page)=>{     
        setLoading(true);   
        fetch(url + '' + nb_page+"?page="+current_page)
                .then(response => response.json())
                .then(json => {
                    setLoading(false);
                    setArticles(json.data);

                    setTotalPosts(json.total);
                    //setCurrentPage(json.from);
                    setLastPage(json.last_page);

                    console.log(json)
                })
                .catch(error => console.log(error));
        
        
    }
    let ldate="";

    return (
        <div className=" py-12 ">
            <div className="mx-auto max-w-5xl px-4 xl:px-8">
                    <div className="max-w-[800px] mx-auto">
                        <h1 className='font-bold text-xl dark:text-gray-300'>Articles</h1>
                <div className="relative border-l ps-4 md:ps-8 my-8 border-gray-200 dark:border-gray-500">
                        <div className=''>
                            {loading && rows && rows.map((nb, index)=>
                                <LoadCard key={index} np={nb} />)}
                            {articles && articles.map((article, index,arr) => {
                                const previousItem = arr[index - 1]?arr[index - 1]:null;
                              ldate= previousItem?moment(previousItem.created_at).format('MMMM YYYY'):'-';
                              
                             return   (<div>
                                    {ldate!=moment(article.created_at).format('MMMM YYYY')?
                                <div className='mt-6'>
                                <div className="absolute invisiblemd:visible w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-500 dark:bg-gray-700"></div>
                                <div className="text-sm capitalize text-muted dark:text-slate-400 mt-2 mb-6">
                                    {moment(article.created_at).format('MMMM YYYY')}  
                                </div>
                                </div>
                                    :''}
                                <CardArticle article={article} index={index} key={index} />
                                </div>)
                            
                                })
                            }
                        </div>
                        <div>
                            <Pagination url={url} postsPerPage={postsPerPage} lastPage={lastPage} currentPage={currentPage}  onchange={HandlePageChange} totalPosts={totalPosts}  />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AllArticles
