
import React, { useEffect } from 'react';
import moment from 'moment';
import frLocale from "../i18/momen-js";
import {  HTTP, PROJETS } from '../datas/config';
import { FaEye } from 'react-icons/fa';


function ShowProjet({ data,id }) {
  moment.locale('fr', [frLocale]);

  let HTTP_WEB = HTTP.WEB ?HTTP.WEB:'';
  let HTTP_SHOW = PROJETS.VIEW ? PROJETS.VIEW : '';
  let projet_id=data.id?data.id:0;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    //body: JSON.stringify({ title: 'OK' })
  };

  useEffect(() => {
    let view=localStorage.getItem('projet_'+projet_id);

    if(view!=null){return;}
    fetch(HTTP_SHOW+''+projet_id, requestOptions)
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('projet_'+projet_id,projet_id);

      })
      .catch((e)=>console.log(e));
  }, [])

  return (
    <div className="max-w-6xl  md:rounded my-12 mx-auto">
      <div className='mx-auto max-w-[800px] border border-zinc-50 p-4  bg-white md:rounded-md md:shadow-sm dark:bg-gray-700 dark:border-gray-700  dark:text-zinc-300'>
        <div >
          <h3 className='font-bold my-6 text-3xl lg:text-3xl me-16 '>{data.titre}</h3>
          {data.nb_view && <div className="text text-xs md:-mt-12 mx-1 md:float-right bg-gray-200  dark:bg-gray-600 px-2 py-1 rounded-full"><FaEye className='inline'/> { data.nb_view?data.nb_view:0} vue{data.nb_view>1?'s':''}</div>}
          <span className="text muted mb-4 px-2 py-1 bg-gray-200  dark:bg-gray-600  text-xs my-2">Début : { data.mois_debut+" "+data.annee_debut}</span>
          {data.duree && <span className="text muted mb-4 px-2 py-1 bg-gray-200  dark:bg-gray-600  text-xs my-2 mx-1">Durée : { data.duree}</span>}
          <span className="text muted mb-4 px-2 py-1 bg-gray-200  dark:bg-gray-600  text-xs my-2 mx-1">Type de projet : { data.type_projet}</span>
        </div>
        <div className="mb-4 my-8 rounded-lg overflow-hidden">
          {data.image && <img src={HTTP_WEB + '' + data.image} className='img-fluid rounded-lg' />}
        </div>
        <div className="grid grid-cols-3">
          <div className="col-span-3">
            <div className=" xl:px-8 text-lg mb-8">

            <div className='mb-4 xl:text-xl line-highlight font-serif main_article' id="main-article" dangerouslySetInnerHTML={{ __html: data.description? data.description.replace(/(<? *script)/gi, 'illegalscript'):data.description }} >
            </div>
          {data.site_web && <span className="text muted mb-4 px-2 py-1 bg-teal-200  dark:bg-gray-600 dark:text-teal-400 dark:border-teal-500 dark:border  rounded-sm my-2 mt-2"> <a href={data.site_web} target="_blank" rel="noopener noreferrer" title={data.site_web}>Lien : { data.site_web && data.site_web.length>26?data.site_web.substring(0,26)+"...":data.site_web}</a></span>}
            </div>
          </div>

          <div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default ShowProjet
