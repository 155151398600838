import DefaultLayout from "./assets/Layouts/DefaultLayout";
import News from "./components/News";
import Articles from "./pages/Articles";
import Index from "./pages/Index";
import { BrowserRouter ,Routes, Route } from 'react-router-dom';
import Speaking from "./pages/Speaking";
import Projets from "./pages/Projets";
import Contact from "./pages/Contact";
import Notfound from "./pages/Notfound";
import Article from "./pages/Article";
import Parcours from "./pages/Parcours";
import Formations from "./pages/Formations";
import Certifications from "./pages/Certifications";
import AutresParcours from "./pages/AutresParcours";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ScrollToTop from "./components/ScrollToTop";
import Projet from "./pages/Projet";

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<DefaultLayout />}>
            
            <Route index element={<Index />} />
            <Route exact path="about" element={<Index />} />
            <Route exact path="articles" element={<Articles />} />
            <Route exact path="articles/page/:page" element={<Articles />} />
            <Route exact path="article/:slug" element={<Article />} />
            <Route exact path="parcours" element={<Parcours />} />
            <Route exact path="parcours/formations" element={<Formations />} />
            <Route exact path="parcours/certifications" element={<Certifications />} />
            <Route exact path="parcours/certifications/page/:page" element={<Certifications />} />
            <Route exact path="parcours/autres" element={<AutresParcours />} />
            <Route exact path="news" element={<News />} />
            <Route exact path="speaking" element={<Speaking />} />
            <Route exact path="projets" element={<Projets />} />
            <Route exact path="projets/page/:page" element={<Projets />} />
            <Route exact path="projet/:slug" element={<Projet />} />

            <Route exact path="terms" element={<Terms />} />
            <Route exact path="privacy" element={<Privacy />} />
            <Route exact path="project/:slug" element={<Projets />} />
            <Route exact path="contact" element={<Contact />} />
            <Route  path="*" element={<Notfound />} />
        </Route>

    </Routes>
  </BrowserRouter>
  );
}

export default App;
