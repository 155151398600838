import React, { useState, useEffect } from 'react'
import { FaAdjust, FaGlobe, FaLocationArrow, FaMapMarked, FaMapPin, FaPlug, FaRoad } from "react-icons/fa";
import moment from 'moment';
import frLocale from "../i18/momen-js";
import { Link } from 'react-router-dom';
import LoadList from '../components/loading/LoadList';
import NavParcours from '../components/NavParcours';
import useTitle from '../components/useTitle';
import { HTTP, PARCOURS } from '../datas/config';

function Formations() {
    const [experiences, setExp] = useState([]);
    const [loading, setLoading] = useState(true);
    
    let HTTP_API = PARCOURS.FORM ?PARCOURS.FORM:''; 
    let HTTP_HOME = HTTP.WEB ?HTTP.WEB:''; 
    
    useTitle('Formations');
    moment.locale('fr', [frLocale]);

    useEffect(() => {
        const getFormations = async (nb) => {
            let API_URL = `${HTTP_API}${nb}`;
            fetch(API_URL)
                .then(response => response.json())
                .then(json => {
                    setExp(json.data);
                    console.log(json.data)
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    //setLoading(false);
                });
        };

        getFormations(20);
    }, []);
    return (
        <div className='mx-auto my-12 max-w-[800px]'>

            <NavParcours menu="formations"/>

            <div className='mx-auto my-8 md:rounded-md md:shadow bg-white p-8 py-12 dark:border-gray-600 dark:bg-gray-700  dark:hover:bg-gray-700/90 dark:text-zinc-300'>
            {loading && (
                        <LoadList/>
                    )}
                <ol className="relative border-l border-gray-200 dark:border-gray-600">
                    {experiences && experiences.map((exp, index) => (
                        <li className="mb-10 ml-4" key={index}>
                            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-700"></div>
                            <time className="mb-1 text-sm font-normal leading-none text-gray-400 font-semibold dark:text-slate-400">{exp.mois_debut+" "+exp.annee_debut } {exp.mois_fin && " — " +exp.mois_fin+" "+exp.annee_fin}</time>
                            {exp.lieu && <div className="text-sm text-slate-400 flex md:float-right "><FaMapMarked className='my-1 mx-1' /> {exp.lieu}</div>}
                            <div className="grid grid-cols-12 w-full md:flex items-center justify-content-between gap-4 font-semibold">
                                {exp.logo && 
                                <div className="logo col-span-4">
                                    <a target='_blanck' href={exp.site_web != '' ? exp.site_web : '#'}>
                                        <img alt={exp.ecole} src={HTTP_HOME + '' + exp.logo} loading="lazy"  decoding="async" data-nimg="1" className="h-24 object-cover  w-24 my-2" />
                                    </a>
                                </div>
                                 }
                                <div className="entreprise col-span-8">
                                    <dl className="flex flex-auto flex-wrap gap-x-2">
                                       
                                        <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
                                         <h3 className="text-lg md:text-xl">  {exp.ecole}</h3> 
                                        </dd>
                                        <dt className="sr-only">{exp.diplome} / {exp.domaine} </dt>
                                        <dd className="text-sm text-zinc-500 dark:text-slate-400">{exp.diplome} / {exp.domaine}  </dd>
                                        
                                    </dl>
                                </div>
                            </div>
                            
                            <p className="mb-4 text-base text-sm_ font-normal text-gray-900 dark:text-slate-400" dangerouslySetInnerHTML={{ __html: exp.description && exp.description.replace(/(<? *script)/gi, 'illegalscript').replace(/(?:\r\n|\r|\n)/g, '<br />') }} >

                            </p>
                            

                        </li>)
                    )}
                </ol>

            </div>
        </div>
    )
}

export default Formations