
import React, { useEffect } from 'react';
import moment from 'moment';
import frLocale from "../i18/momen-js";

import { ARTICLES, HTTP } from '../datas/config';
import { FaCalendar, FaEye } from 'react-icons/fa';

function Showdata({ data }) {
  let HTTP_WEB = HTTP.WEB ? HTTP.WEB : '';
  moment.locale('fr', [frLocale]);
  let HTTP_SHOW = ARTICLES.VIEW ? ARTICLES.VIEW : '';
  let article_id=data.id?data.id:0;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  useEffect(() => {
    let view=localStorage.getItem('article_'+article_id);
   
    if(view!=null){return;}
    fetch(HTTP_SHOW+''+article_id, requestOptions)
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('article_'+article_id,article_id);

      })
      .catch((e)=>console.log(e));
  }, [])


  return (
    <div key={data.id} className="max-w-6xl  md:rounded my-12 mx-auto">
      <div className='mx-auto max-w-[800px] border border-zinc-50 p-4 bg-white md:rounded-md md:shadow-sm dark:bg-gray-700 dark:border-gray-700  dark:text-zinc-300'>
        <div className="text-center">
          <h3 className='font-bold my-6 text-3xl lg:text-4xl text-center'>{data.titre}</h3>
          <span className="text muted mb-4 px-2 py-1 bg-gray-200 rounded-full  dark:bg-gray-600  text-xs my-2 "> <FaCalendar className='inline'/> {moment(data.created_at).format('D MMMM YYYY')}</span>
          <span className="text muted mb-4 px-2 mx-2 py-1 bg-gray-200 rounded-full  dark:bg-gray-600  text-xs my-2"> <FaEye className='inline'/> {data.nb_view?data.nb_view:1} vue{data.nb_view>1?'s':''}</span>
        </div>
        <div className='text-muted text-[18px] md:p-4 bg-zinc-100_ rounded-md my-4 xl:px-8 '>
          {data.resume}
        </div>
        <div className="mb-4 overflow-hidden rounded-lg">
          {data.image && <img src={HTTP_WEB + '' + data.image} className='img-fluid rounded-lg' />}
        </div>
        <div className="grid grid-cols-3">
          <div className="col-span-3">
            <div className='overflow-hidden  xl:px-8 text-lg xl:text-xl line-highlight font-serif main_article' id="main-article" dangerouslySetInnerHTML={{ __html: data.contenu ? data.contenu.replace(/(<? *script)/gi, 'illegalscript') : data.contenu }} >
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Showdata;
