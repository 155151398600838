
export const ARTICLES = {
    'ALL': "https://admin.wes.odacesoft.com/api/v1/articles/",
    'SHOW': "https://admin.wes.odacesoft.com/api/v1/article/",
    'VIEW': "https://admin.wes.odacesoft.com/api/v1/article/view/",
    'RECOM': "https://admin.wes.odacesoft.com/api/v1/recommandation-articles/",
  };

export const PROJETS = {
    'ALL': "https://admin.wes.odacesoft.com/api/v1/projets/",
    'SHOW': "https://admin.wes.odacesoft.com/api/v1/projet/",
    'VIEW': "https://admin.wes.odacesoft.com/api/v1/projet/view/",
    'RECOM': "https://admin.wes.odacesoft.com/api/v1/recommandation-projets/"
  };
export const PARCOURS = {
    'ALL': "https://admin.wes.odacesoft.com/api/v1/parcours/",
    'FORM': "https://admin.wes.odacesoft.com/api/v1/formations/",
    'CERTIF': "https://admin.wes.odacesoft.com/api/v1/certifications/"
  };

  export const HTTP = {
    'WEB': "https://admin.wes.odacesoft.com/",
  };
